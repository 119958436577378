import React from "react";
import * as F from "./FeaturesCards.style";

import StepCard from "components/general/stepCard/StepCard";
// Images
import SmileyOne from "assets/images/new/Smiley-1.png";
import SmileyTwo from "assets/images/new/Smiley-2.png";
import SmileyThree from "assets/images/new/Smiley-3.png";

const FeaturesCards = () => {
  const Cards = [
    {
      image: SmileyOne,
      title: "Eigener Ernährungsberater",
      description: "Betreuung durch deinen persönlichen Foodiary Coach.",
    },
    {
      image: SmileyTwo,
      title: "Foodiary App mit allen Features",
      description:
        "Inklusive Foodiary PRO mit dem gesamten Leistungsspektrum und allen Features.",
    },
    {
      image: SmileyThree,
      title: "Umfangreiche Wissensplattform",
      description:
        "Ernährungsplattform mit Artikeln, Videos, Kursen und vieles mehr.",
    },
  ];

  return (
    <F.Wrap>
      <F.CardsWrap>
        {Cards.map((item, i) => (
          <F.Card key={i}>
            <img src={item?.image} alt="pro" />
            <F.CardTitle className="ct-text-block text-lg font-medium color-dark p-6 text-center">
              {item?.title}
            </F.CardTitle>
            <F.CardDescription className="ct-text-block color-paragraph font-normal text-base text-center">
              {item.description}
            </F.CardDescription>
          </F.Card>
        ))}
      </F.CardsWrap>
    </F.Wrap>
  );
};

export default FeaturesCards;
