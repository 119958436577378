import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { PopupButton } from "react-calendly";
import * as C from "./FullSupportWithPersonalCoach.styled";
import Chip from "components/general/formComponents/chip/Chip";
// Images
import FoodiaryErstgesprach from "assets/images/App/High-Five.svg";

const FullSupportWithPersonalCoach = ({ onLeadFormModal }) => {
  const history = useHistory();

  return (
    <C.CoachingAccordianContainer>
      <C.CoachingAccordianRight>
        <img src={FoodiaryErstgesprach} alt="consultation" />
      </C.CoachingAccordianRight>
      <C.CoachingAccordianLeft>
        <Chip text="ERSTGESPRÄCH" margin="0 0 3rem 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
          Vollumfänglich unterstützt mit meinem persönlichen Coaching
        </C.Title>

        <C.Info className="ct-text-block color-paragraph text-base text-left">
          Wünschst du dir maßgeschneiderte und persönliche Hilfe, um deine
          Ernährung umzustellen und gleichzeitig nachhaltig zu erlernen, wie
          eine ausgewogene Ernährung aussieht?
          <br />
          <br />
          Gerne begleite ich dich in meinem individuellen Coaching, wo du nicht
          nur vollen Zugriff auf alle Funktionen der Foodiary App PRO erhältst,
          sondern auch die Möglichkeit hast, von der Lernacademy und dem
          zertifizierten Onlinekurs zu profitieren.
          <br />
          <br />
          Zusätzlich werde ich an deiner Seite stehen und dir im Alltag mit
          nützlichen Tipps und Tricks zur Seite stehen.
        </C.Info>

        <button
          className="ct-link-text foodiary-green-button cursor-pointer mt-8"
          onClick={() => onLeadFormModal()}
        >
          Erstgespräch vereinbaren
        </button>

        {/* <PopupButton
          className="ct-link-text foodiary-green-button cursor-pointer"
          url={`${coach?.pp_first_meeting_calendly_url_profilpage}?hide_gdpr_banner=1`}
          rootElement={document.getElementById("root")}
          text="Erstgespräch vereinbaren"
        /> */}
      </C.CoachingAccordianLeft>
    </C.CoachingAccordianContainer>
  );
};

export default FullSupportWithPersonalCoach;
