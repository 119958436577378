import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { PopupButton } from "react-calendly";
import ReactPlayer from "react-player";
import Chip from "components/general/formComponents/chip/Chip";
import * as C from "./ReimburseHealthInsurance.styled";
// Images
import Logo1 from "assets/images/App/techniker-krankenkasse-logo.svg";
import Logo2 from "assets/images/App/aok.svg";
import Logo3 from "assets/images/App/DAK-Gesundheit_logo.svg";
import Logo4 from "assets/images/App/barmer_krankenkasse_logo.svg";
import Logo5 from "assets/images/App/ikk-gesundplus.svg";

const ReimburseHealthInsurance = ({ changeOrder = false, onLeadFormModal }) => {
  const history = useHistory();

  const coach = useSelector((state) => state.coach.coach);

  return (
    <C.Wrap
      className={
        changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap"
      }
    >
      <C.TextContent className="text-content">
        <Chip text="FOODIARY KURS" margin="0 0 30px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
          Bis zu 100 % Kostenerstattung von deiner Krankenkasse
        </C.Title>
        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Im Rahmen des Foodiary 8-Wochen Präventionskurses bieten sich dir eine
          strukturierte Lernumgebung mit 8 Einheiten. Jede Einheit beinhaltet
          informative Videos, in denen das Grundlagenwissen zur gesunden
          Ernährung und Prävention vermittelt wird. Die Videos haben eine Dauer
          von 45 bis 60 Minuten und sind praxisbezogen gestaltet, um dir das
          Selbstlernen zu erleichtern.
        </C.Description>
        <br />
        <C.Description className="ct-text-block color-dark font-medium text-base mb-4">
          UNTERSTÜTZ DURCH:
        </C.Description>
        <C.InsuranceCompanies>
          <div className="company-item">
            <img src={Logo1} alt="" />
          </div>
          <div className="company-item">
            <img src={Logo2} alt="" />
          </div>
          <div className="company-item">
            <img src={Logo3} alt="" />
          </div>
          <div className="company-item">
            <img src={Logo4} alt="" />
          </div>
          <div className="company-item">
            <img src={Logo5} alt="" />
          </div>
        </C.InsuranceCompanies>

        <C.Action className="mt-8">
          <button
            className="ct-link-text foodiary-green-button cursor-pointer"
            onClick={() => onLeadFormModal()}
          >
            Erstgespräch vereinbaren
          </button>

          {/* <PopupButton
            className="ct-link-text foodiary-green-button cursor-pointer"
            url={`${coach?.pp_first_meeting_calendly_url_profilpage}?hide_gdpr_banner=1`}
            rootElement={document.getElementById("root")}
            text="Erstgespräch vereinbaren"
          /> */}
        </C.Action>
      </C.TextContent>
      <C.VisualContent>
        <ReactPlayer
          url={"https://foodiary.app/data/Foodiary-Kurs.mp4"}
          playsinline
          playing
          muted
          loop
        />
        {/* <img src={StartTogetherImage} alt="together" /> */}
        {/* https://foodiary.app/data/Prevention-Dashboard-animiert.mp4 */}
      </C.VisualContent>
    </C.Wrap>
  );
};

export default ReimburseHealthInsurance;
