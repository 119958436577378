import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Navbar from "components/Navbar/Navbar";
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import CoachingWithMe from "components/CoachingWithMe/CoachingWithMe";
import WhyIamYourCoach from "components/WhyIamYourCoach/WhyIamYourCoach";
import StartNutritionChange from "components/StartNutritionChange/StartNutritionChange";
import FreeInitialConsultation from "components/FreeInitialConsultation/FreeInitialConsultation";
import FooterNew from "components/FooterNew/FooterNew";
import MealPlanTools from "components/MealPlanTools/MealPlanTools";
import CoachingGlance from "components/CoachingGlance/CoachingGlance";
import CoachingAccordian from "components/CoachingAccordian/CoachingAccordian";
import SectionWrapper from "components/general/sectionWrapper/SectionWrapper";
import TestimonialSlider from "components/TestimonialSlider/TestimonialSlider";

// Redux
import * as CoachActions from "redux/coach/coach.actions";

// Styles
import * as G from "styles/General.Styles";
import * as C from "./myCoaching.styles";
import FeaturesCards from "components/FeaturesCards/FeaturesCards";
import CoachingProFeatures from "components/CoachingProFeatures/CoachingProFeatures";
import InitialConsultationBox from "components/InitialConsultationBox/InitialConsultationBox";
import FreeInitialConsultationWithSurvey from "components/FreeInitialConsultationWithSurvey/FreeInitialConsultationWithSurvey";
import HelpWithSurvey from "components/HelpWithSurvey/HelpWithSurvey";
import ReimburseHealthInsurance from "components/ReimburseHealthInsurance/ReimburseHealthInsurance";
import LeadSurveyModal from "modals/LeadSurveyModal/LeadSurveyModal";

const MyCoaching = (props) => {
  const dispatch = useDispatch();
  const coach = useSelector((state) => state.coach.coach);

  const [openLeadModal, setOpenLeadModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    // const URL =
    //   "https://vinzenz-fci73124.foodiary.app".split(".app")[0] + ".app";

    // const URL = "https://helen-fci81909.foodiary.app".split(".app")[0] + ".app";

    const URL = window.location.href.split(".app")[0] + ".app";

    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url: URL },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          );
        },
      })
    );
  }, [dispatch]);

  return (
    <>
      <C.Header>
        <C.HeaderInner>
          <G.ContainerNavbar>
            {/* <Navbar /> */}
            <StickyNavbar
              openSurveyModal={() => setOpenLeadModal(!openLeadModal)}
            />
          </G.ContainerNavbar>
          <G.Container>
            <SectionWrapper margin="19.5rem 0 0" padding="0 0 35.5rem">
              <CoachingWithMe />
            </SectionWrapper>
          </G.Container>
        </C.HeaderInner>
      </C.Header>
      <G.Container>
        <SectionWrapper margin="-17rem 0 0">
          <FeaturesCards />
        </SectionWrapper>
        <SectionWrapper margin="14.8rem 0 0" mobileMargin="6.8rem 0 0">
          <WhyIamYourCoach />
        </SectionWrapper>
        <SectionWrapper margin="10.8rem 0 0" mobileMargin="10.8rem 0 0">
          <HelpWithSurvey />
        </SectionWrapper>
        <SectionWrapper margin="15.8rem 0 0" mobileMargin="10.8rem 0 0">
          <CoachingAccordian />
        </SectionWrapper>
        <SectionWrapper margin="15.8rem 0 0" mobileMargin="10.8rem 0 0">
          <ReimburseHealthInsurance
            onLeadFormModal={() => setOpenLeadModal(true)}
          />
        </SectionWrapper>
        {/* <SectionWrapper margin="20.8rem 0 0" mobileMargin="10.8rem 0 0">
          <CoachingProFeatures />
        </SectionWrapper>
        <SectionWrapper margin="20.8rem 0 0" mobileMargin="10.8rem 0 0">
          <FreeInitialConsultation />
        </SectionWrapper>
        <SectionWrapper margin="20.8rem 0 0" mobileMargin="10.8rem 0 0">
          <CoachingGlance />
        </SectionWrapper>
        <SectionWrapper margin="20.8rem 0 0" mobileMargin="10.8rem 0 0">
          <MealPlanTools />
        </SectionWrapper> */}
        <SectionWrapper margin="20.8rem 0 0" mobileMargin="10.8rem 0 0">
          <TestimonialSlider />
        </SectionWrapper>
      </G.Container>
      <SectionWrapper margin="7.8rem 0 0">
        <InitialConsultationBox
          openLeadFormModal={() => setOpenLeadModal(true)}
        />
      </SectionWrapper>
      <FooterNew />

      {coach && (
        <LeadSurveyModal
          isOpen={openLeadModal}
          onClose={() => setOpenLeadModal(false)}
          coachProfile={coach}
        />
      )}
    </>
  );
};

export default MyCoaching;
